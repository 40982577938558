<template>
    <div>
        <img alt="Image" :src="require('@/assets/NE_STL.jpg')">
    </div>
</template>

<script>
export default {
    name: "Home",
    data() {
        return {
            title: "Nebraska Raised. St. Louis Crazed."
        }
    }
}
</script>

<style scoped>
    .bigText {
        font-family: Verdana, Helvetica, Arial, Tahoma, sans-serif;
        font-size: 9.75vw;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 50%;
        text-align: center;
    }
    img {
        border-radius: 50%;
        padding-top: 15px;
        width: 90vw;
    }
</style>