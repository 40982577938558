<template>
   <Menubar :model="items">
      <!-- <template #start>
         Before
      </template> -->
      <!-- <template #end>
         Contact us: (402) 336-4222
      </template> -->
   </Menubar>
    <!-- <Button label="greet"></Button> -->
</template>

<script>
export default {
  name: 'NavBar',
  data() {
        return {
            items: [
                {
                   label: 'Home',
                   to: '/'
                },
                {
                   label: 'Projects',
                   to: '/projects'
                },
                {
                    label: 'Recipes',
                    url: 'http://www.ianlueninghoener.com/Recipes'
                },
                {
                    label: 'Pickems',
                    url: 'http://www.ianlueninghoener.com/Pickems'
                }
             ]
        }
    }
}
</script>

<!-- Add CSS here -->
<style>
</style>